import React from "react";
import { SlCallIn } from "react-icons/sl";
import { CiLocationOn } from "react-icons/ci";
import { SiHelpscout } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { HiOutlineOfficeBuilding } from "react-icons/hi";

const ContactUs = () => {
    const Business = [
        {
            id: 1,
            title: "Restaurant",
        },
        {
            id: 2,
            title: "Bakery",
        },
        {
            id: 3,
            title: "Van Sales",
        },
        {
            id: 4,
            title: "Cake Shop",
        },
        {
            id: 5,
            title: "Salon",
        },
        {
            id: 6,
            title: "Juice Parlour",
        },
        {
            id: 7,
            title: "Fuel Outlet",
        },
        {
            id: 8,
            title: "Vegetable Shop",
        },
        {
            id: 9,
            title: "Retail Shop",
        },
    ];

    return (
        <section className="max-w-[1024px] m-auto px-4">
            <div className="font-poppins">
                <div className="flex flex-col text-center w-full lg:mb-6">
                    <p className="mx-auto leading-relaxed text-2xl sm:text-[2rem]">
                        Get in Touch with finix-tf
                    </p>
                    <p>Have questions, feedback, or inquiries? We'd love to hear from you! Reach out to us using the contact information below:</p>
                </div>
                <div className="grid lg:grid-cols-2">
                    <section className="text-black body-font relative hidden">
                        <div className="py-2 mx-auto">
                            <div className="w-full px-2 mx-auto">
                                <div className="flex flex-wrap -m-2">
                                    <div className="p-2 w-1/2">
                                        <div className="relative text-sm">
                                            <label className="leading-7">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="relative text-sm">
                                            <label className="leading-7">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="relative text-sm">
                                            <label className="leading-7">
                                                Phone
                                            </label>
                                            <input
                                                type="text"
                                                id="phone"
                                                name="phone"
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            />
                                        </div>
                                    </div>
                                    <div className="p-2 w-1/2">
                                        <div className="relative text-sm">
                                            <label className="leading-7">
                                                Business
                                            </label>
                                            <select
                                                name="business"
                                                id="business"
                                                className="w-full h-[42px] bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                            >
                                                <option value="" disabled selected>
                                                    Select your business
                                                </option>
                                                {Business.map((business) => {
                                                    return (
                                                        <option value={business.title}>
                                                            {business.title}
                                                        </option>
                                                    );
                                                })}
                                                <option value="other">Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <div className="relative text-sm">
                                            <label className="leading-7">
                                                Message
                                            </label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 outline-none py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="p-2 w-full">
                                        <button className="w-full text-white bg-[#19354c] border-0 py-2 px-8 focus:outline-none hover:bg-[#376891] rounded text-lg">
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div>
                        <div className="w-full flex flex-col sm:flex-row p-4">
                            <div className="mr-4">
                                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-sky-200 text-black">
                                    <CiLocationOn size={"20px"} />
                                </div>
                            </div>
                            <div className="mt-2">
                                <h6 className="mb-3 text-xl font-bold leading-5">
                                    Office Address:
                                </h6>
                                <p>FINIX-TF</p>
                                <p>Shahana Complex, 3rd floor,</p>
                                <p>Opp. Govt. Hospital, Housing Colony Road,</p>
                                <p>Perinthalmanna, Malappuram Dist.</p>
                                <p>PIN 679322, Kerala, India</p>
                                <p className="mt-2"><b>Phone:</b> <a href="tel:+919946459070">+919946459070</a></p>
                                <p className="mt-2"><b>Email:</b> {" "} <span className="text-blue-600 text-underline" ><a href="mailto:finixtfsolution@gmail.com">finixtfsolution@gmail.com</a></span></p>
                            </div>
                        </div>
                        <div className="w-full flex flex-col sm:flex-row p-4">
                            <div className="mr-4">
                                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-sky-200 text-black">
                                    <SiHelpscout size={"20px"} />
                                </div>
                            </div>
                            <div className="mt-2">
                                <h6 className="mb-3 text-xl font-bold leading-5">
                                    Connect with us:
                                </h6>
                                <div className="flex mb-3 lg:mb-0 space-y-0 sm:space-x-5 flex-row gap-2 mt-4">
                                    <FaFacebookF className="hover:animate-bounce" size={"20px"} />
                                    <FaInstagram className="hover:animate-bounce" size={"20px"} />
                                    <FaLinkedinIn className="hover:animate-bounce" size={"20px"} />
                                    <FaYoutube className="hover:animate-bounce" size={"20px"} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="grid lg:grid-cols-2">
                    <div>
                        <div className="w-full flex flex-col sm:flex-row p-4">
                            <div className="mr-4">
                                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-sky-200 text-black">
                                    <SlCallIn size={"20px"} />
                                </div>
                            </div>
                            <div className="mt-2">
                                <h6 className="mb-3 text-xl font-bold leading-5">
                                    Customer Support
                                </h6>
                                <p className="text-justify">Our dedicated customer support team is available to assist you with any queries or concerns. For prompt assistance, please contact our customer support hotline or send us an email. We strive to respond to all inquiries within 24 hours.</p>
                            </div>
                        </div>
                        <div className="w-full flex flex-col sm:flex-row p-4">
                            <div className="mr-4">
                                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-sky-200 text-black">
                                    <SlCallIn size={"20px"} />
                                </div>
                            </div>
                            <div className="mt-2">
                                <h6 className="mb-3 text-xl font-bold leading-5">Business Inquiries</h6>
                                <p className="text-justify">For business-related inquiries, partnerships, or collaborations, please reach out to our business development team. We are open to exploring opportunities that align with our vision and values.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="w-full flex flex-col sm:flex-row p-4 text-justify">
                            <div className="mr-4">
                                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-sky-200 text-black">
                                    <HiOutlineOfficeBuilding size={"20px"} />
                                </div>
                            </div>
                            <div>
                                <h6 className="mb-3 text-xl font-bold leading-5 mt-2">
                                    Visit Our Office
                                </h6>
                                <p>If you prefer face-to-face communication, feel free to visit our office during business hours. Our team would be delighted to meet with you.</p>
                                <p className="py-2">
                                    <b>Office Hours:</b> <br />
                                    Monday - Saturday: 9:00 AM - 6:00 PM (Local Time)<br />
                                    Sunday: Closed
                                </p>
                                <p>We appreciate your interest in finix-tf, and we look forward to assisting you on your journey to business success!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="font-bold text-center text-xl py-2">
                Elevate Your Business with finix-tf - Where Innovation Meets Efficiency!
            </div>
        </section>
    );
};
export default ContactUs;
