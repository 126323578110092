import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { RouteNavigation } from "../../Routes";

const Footer = () => {
    return (
        <div className="text-white font-ico">
            <div className="footer-top-bg p-4">
                <div className="grid gap-10 py-16 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-w-[1024px] m-auto">
                    <div className="sm:col-span-2 lg:col-span-1">
                        <span>
                            <img src="./image/logo.svg" className="w-16" alt="logo" />
                        </span>
                        <p className="text-sm py-6 font-imprima-regular">
                            Elevate Your Business, Simplify Transactions - Where Innovation Meets Efficiency for Ultimate Success!
                        </p>
                        <Link to={"https://api.whatsapp.com/send/?phone=9946459039&text&type=phone_number&app_absent=0"}>
                            <button className="footer-button-bg p-2 px-5 rounded-md font-imprima-regular">
                                Get a Quote
                            </button>
                        </Link>
                    </div>
                    <div className="font-objectivity-regular">
                        <p className="font-semibold tracking-wide text-2xl">
                            Catagories
                        </p>
                        <ul className="mt-2 space-y-2 mt-5">
                            <li>
                                <Link to={RouteNavigation.home}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                Solutions
                            </li>
                            <li>
                                Features
                            </li>
                            <li>
                                <Link to={RouteNavigation.about}>
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link to={RouteNavigation.contact}>
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="font-objectivity-regular">
                        <p className="font-semibold tracking-wide text-2xl">
                            Business Solutions
                        </p>
                        <div className="flex gap-[5rem] mt-5">
                            <ul className="mt-2 space-y-2">
                                <li>
                                    Restaurant
                                </li>
                                <li>
                                    Bakery
                                </li>
                                <li>
                                    Van Sales
                                </li>
                                <li>
                                    Cake Shop
                                </li>
                                <li>
                                    Salon
                                </li>
                            </ul>
                            <ul className="mt-2 space-y-2">
                                <li>
                                    Salon
                                </li>
                                <li>
                                    Juice Parlor
                                </li>
                                <li>
                                    Fuel Outlets
                                </li>
                                <li>
                                    Vegetable Shop
                                </li>
                                <li>
                                    Retail Shops
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-bg p-4">
                <div className="max-w-[1024px] m-auto flex flex-col lg:flex-row item-center lg:justify-between gap-y-4">
                    <p className="text-sm font-fredoka-regular">
                        2024 FINIX - TF All rights reserved
                    </p>
                    <div className="flex mb-3 space-y-2 items-baseline space-x-5 flex-row">
                        <FaFacebookF />
                        <FaInstagram />
                        <FaLinkedinIn />
                        <FaYoutube />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;