import React from 'react';
const AboutUs = () => {
    return (
        <section className="">
            <div className='max-w-[1024px] m-auto px-4'>
                <div className="flex flex-col text-center w-full lg:mb-6">
                    <p className="mx-auto leading-relaxed text-2xl sm:text-[2rem]">
                        About us
                    </p>
                </div>
                <div className="mx-auto flex py-8 md:flex-row flex-col-reverse items-center">
                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium ">Welcome to finix-tf:
                            <br className="hidden lg:inline-block" />Your Business's Growth Partner
                        </h1>
                        <p className="mb-8 leading-relaxed">At finix-tf, we understand that the heart of any successful business lies in efficient operations, seamless transactions, and reliable financial management. That's why we've developed the world's finest compact Multi-Platform POS Software, meticulously crafted to elevate your business to new heights.</p>
                    </div>
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img className="object-cover object-center rounded" alt="hero" src="https://images.pexels.com/photos/977840/pexels-photo-977840.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" />
                    </div>
                </div>
            </div>

            <section className='bg-gray-200 px-4'>
                <div className="py-16 max-w-[1024px] m-auto ">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font text-center  mb-10">About Us
                    </h1>
                    <div className="grid grid-cols-1 lg:grid-cols-3">
                        <div className="p-4 w-full flex">
                            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
                                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                </svg>
                            </div>
                            <div className="flex-grow pl-6">
                                <h2 className=" text-lg title-font font-medium mb-2">Unparalleled Features Versatility</h2>
                                <p className="leading-relaxed text-base">Ideal for different business types, from supermarkets, restaurants and salons to distribution and home delivery services.</p>
                            </div>
                        </div>
                        <div className="p-4 w-full flex">
                            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
                                    <circle cx="6" cy="6" r="3"></circle>
                                    <circle cx="6" cy="18" r="3"></circle>
                                    <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                                </svg>
                            </div>
                            <div className="flex-grow pl-6">
                                <h2 className=" text-lg title-font font-medium mb-2">Comprehensive Financial Tools:</h2>
                                <p className="leading-relaxed text-base">Inclusive GST/VAT features, detailed sales reports, robust stock and inventory management, and thorough tax reports.</p>
                            </div>
                        </div>
                        <div className="p-4 w-full flex">
                            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4 flex-shrink-0">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-6 h-6" viewBox="0 0 24 24">
                                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                            </div>
                            <div className="flex-grow pl-6">
                                <h2 className=" text-lg title-font font-medium mb-2">Effortless Integration</h2>
                                <p className="leading-relaxed text-base">Seamless integration with merchant services ensures smooth transactions and financial processes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='max-w-[1024px] m-auto px-4'>
                <div className="px-5 py-8 mx-auto">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font text-center  mb-8">Our Vision</h1>
                    <div className="text-center">
                        Empowering businesses of all sizes, finix-tf envisions a future where technology seamlessly integrates with diverse industries, simplifying day-to-day operations and fostering growth. We aim to be the catalyst for positive change, offering innovative solutions that transcend traditional boundaries.
                    </div>
                </div>
            </section>

            <section className='max-w-[1024px] m-auto px-4'>
                <div className="px-5 py-4 mx-auto">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font text-center  mb-8">Why Choose finix-tf?</h1>
                    <div className="container px-5 py-2 mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="p-2 w-full">
                                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span className="title-font font-medium"><b>Time and Cost Savings:</b> <br />Streamline your operations and save valuable time and resources.</span>
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span className="title-font font-medium"><b> Accessibility:</b> <br />Access your business data anytime, anywhere, from any device.</span>
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span className="title-font font-medium"><b> Return on Investment (ROI): </b> <br />Enhance your business's profitability through improved efficiency and management capabilities.</span>
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span className="title-font font-medium"><b> User-Friendly Interface:</b> <br />Designed for users of all ages, ensuring ease of use and reducing the learning curve.</span>
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span className="title-font font-medium"><b> Security Measures: </b> <br />Your business data is our priority. finix-tf incorporates high-security measures to safeguard your information.</span>
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="bg-gray-100 rounded flex p-4 h-full items-center">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" className="text-indigo-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                    <span className="title-font font-medium"><b> Responsive Customer Support and Community: </b> <br />Finix-tf's responsive support builds a community, ensuring prompt assistance, shared experiences, and ongoing updates, fostering trust.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='max-w-[1024px] m-auto px-4'>
                <div className="px-5 py-8 mx-auto">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font text-center  mb-8">Join the finix-tf Community</h1>
                    <div className="text-center">
                        Whether you're a small startup or an established enterprise, finix-tf is here to be your trusted companion on the journey to success. Our commitment to innovation, reliability, and customer satisfaction sets us apart. Join the finix-tf community today and experience the transformation of your business.
                    </div>
                </div>
            </section>

            <section className='max-w-[1024px] m-auto px-4'>
                <h1 className="text-xl font-medium title-font text-center  mb-8">Elevate. Simplify. Succeed. Choose finix-tf.</h1>
            </section>
        </section>
    )
}
export default AboutUs;