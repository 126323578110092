import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Link } from "react-router-dom";
import { RouteNavigation } from "../../Routes";
// import { FaRegBuilding } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
// import { FaHome } from "react-icons/fa";

interface MyComponentProps {
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const SideBar: React.FC<MyComponentProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {

  const handleNavbarClick = () => {
    if (window.innerWidth <= 1024) {
      setIsMenuOpen(false);
    }
  };
  const handleResize = () => {
    // Check the window width and update the state accordingly
    if (window.innerWidth >= 1024) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <aside
      id="default-sidebar"
      className={`${isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } fixed top-0 right-0 bottom-0 z-40 w-64 h-screen transition-transform bg-sky-700 text-black duration-300`}
      aria-label="Sidebar"
    >
      <div className="flex gap-5 justify-between items-center mt-5 px-6">
        <h4 className="text-center text-2xl font-semibold">Finix-TF</h4>
        <div onClick={() => setIsMenuOpen(false)}>
          <AiOutlineClose
            className="cursor-pointer"
          // onClick={(e) => toggleNavbar()}
          />
        </div>
      </div>
      <div className="h-full px-3 py-4 overflow-y-auto">
        <ul className="space-y-2 font-medium">
          <li>
            <Link
              to={RouteNavigation.home}
              className="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-black group"
              onClick={handleNavbarClick}
            >
              {/* <FaHome /> */}
              <span className="ms-3">Home</span>
            </Link>
          </li>
          <li>
            <a
              href="/"
              className="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-black group"
              onClick={handleNavbarClick}
            >
              {/* <FaRegBuilding /> */}
              <span className="ms-3">Features</span>
            </a>
          </li>
          <li>
            <Link
              to={RouteNavigation.about}
              className="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-black group"
              onClick={handleNavbarClick}
            >
              {/* <FaRegBuilding /> */}
              <span className="ms-3">About</span>
            </Link>
          </li>
          <li>
            <Link
              to={RouteNavigation.contact}
              className="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-black group"
              onClick={handleNavbarClick}
            >
              {/* <FaRegBuilding /> */}
              <span className="ms-3">Contact Us</span>
            </Link>
          </li>
          <li>
            <Link
              to={"https://login.finix-tf.com/"}
              className="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-black group"
              onClick={handleNavbarClick}
            >
              {/* <FaRegBuilding /> */}
              <span className="ms-3">Login</span>
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default SideBar;
