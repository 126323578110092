import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Page from "./pages";

export const RouteNavigation = {
  home: "/",
  contact: "/contact-us",
  about: "/about-us",
  page_not_found: "*",
};

const GenericRoutes = () => {
  return (
    <Routes>
      <Route path={RouteNavigation.home} element={<Page.Home />} />
      <Route path={RouteNavigation.about} element={<Page.About />} />
      <Route path={RouteNavigation.contact} element={<Page.Contact />} />
    </Routes>
  );
};
export default GenericRoutes;
