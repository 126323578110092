import React from "react";
import BussinessGrid from "./BussinessGrid";
import {
  Carousel,
  initTE,
} from "tw-elements";
import * as Component from "../../Components";
import { IoShieldCheckmarkOutline } from "react-icons/io5";

const Home = () => {
  initTE({ Carousel });
  return (
    <div>
      <img className="w-full" src="./image/banner_01.png" alt="banner" />
      <div className="gray-bg text-center py-8">
        <h2 className="font-nuber-bold text-3xl 2xl:text-5xl">FINIX - TF</h2>
        <h4 className="font-nuber-regular text-3xl 2xl:text-5xl">Business Solutions</h4>
        <p className="font-imprima-regular 2xl:text-2xl pt-6">Explore the power of finix-tf, your all-in-one Multi-Platform POS solution. Revolutionize your business with seamless <br className="hidden lg:flex" /> GST/VAT integration, robust inventory management, and user-friendly interfaces. Discover a new era of efficiency.
        </p>
      </div>

      <BussinessGrid />

      <div className="px-4 py-8 gray-bg">
        <div className="grid gap-10 grid-cols-reverse lg:grid-cols-2 gap-5 max-w-[1024px] m-auto">
          <div className="order-2 lg:order-1">
            <h2 className="font-nuber-bold text-center lg:text-start text-xl lg:text-4xl 2xl:text-5xl py-2 text-bold">Why you need choose <br /> FINIX -TF ?</h2>
            <p className="font-imprima-regular text-center lg:text-start text-xl 2xl:text-2xl py-2">Finix-tf stands out as the ultimate choice for businesses, offering a seamless blend of versatility, efficiency, and innovation. </p>
            <div className="py-5 flex flex-col items-center">
              <div className="flex sm:w-[500px] gap-5 bg-white px-5 py-2 rounded-2xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] my-3">
                <div className="medium-blue-bg w-fit m-auto p-4 rounded-2xl">
                  <img className="w-10" src="./image/element_01.png" alt="element" />
                </div>
                <div className="w-3/4">
                  <h4 className="font-nuber-bold text-xl 2xl:text-2xl">User-Friendly Interface</h4>
                  <p className="font-imprima-regular text-justify text-sm lg:text-lg 2xl:text-xl">Intuitive Design, Effortless Transactions – Where User-Friendly Meets Business Excellence with finix-tf.</p>
                </div>
              </div>
              <div className="flex sm:w-[500px] gap-5 bg-white px-5 py-2 rounded-2xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] my-3">
                <div className="medium-blue-bg w-fit m-auto p-4 rounded-2xl">
                  <IoShieldCheckmarkOutline className="text-white m-auto" size={"40px"} />
                </div>
                <div className="w-3/4">
                  <h4 className="font-nuber-bold text-xl 2xl:text-2xl">Robust Security</h4>
                  <p className="font-imprima-regular text-justify text-sm lg:text-lg 2xl:text-xl">Fortify Your Business Future – Unmatched Security with finix-tf for Peace of Mind.</p>
                </div>
              </div>
              <div className="flex sm:w-[500px] gap-5 bg-white px-5 py-2 rounded-2xl shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] my-3">
                <div className="medium-blue-bg w-fit m-auto p-4 rounded-2xl">
                  <img className="w-10" src="./image/element_03.png" alt="element" />
                </div>
                <div className="w-3/4">
                  <h4 className="font-nuber-bold text-xl 2xl:text-2xl">Seamless Financial Management</h4>
                  <p className="font-imprima-regular text-justify text-sm lg:text-lg 2xl:text-xl">Unlock Financial Success: Seamless Transactions, Prudent Management - Empower Your Business with finix-tf.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="order-1 lg:order-2 m-auto">
            <img
              className="h-[250px] w-[320px] sm:h-[400px] sm:w-[500px] lg:h-auto lg:w-full rounded-2xl "
              src="./image/home_01.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="px-4 py-16 max-w-[1024px] m-auto">
        <div>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5 m-auto">
              <div className="shadow-[20px_-20px_0px_0px_#2252DC] rounded-2xl w-fit">
                <img className="rounded-2xl" src="./image/home_02.png" alt="home" />
              </div>
            </div>
            <div className="lg:w-1/2 text-center lg:text-start self-center">
              <h4 className="text-[#154FD8] font-hustle-regular text-lg lg:text-xl">What we provide</h4>
              <h2 className="font-nuber-bold text-2xl lg:text-4xl 2xl:text-5xl py-3">Trustworthy options for your business needs.</h2>
              <p className="font-imprima-regular text-lg lg:text-xl lg:text-justify pt-6">Trusted Solutions Tailored to Your Needs, Empowering Growth, and Ensuring Success in Every Customer Interaction. Meeting Every Business Need with Reliability, Precision, and Unparalleled Customer Satisfaction.</p>
            </div>
          </div>
        </div>
      </div>


      <div className="px-4 py-12 bg-gray-200 xl:h-[480px]">
        <h2 className="text-2xl font-bold mb-4 text-center font-poppins-regular text-[#154FD8] text-xl xl:text-4xl 2xl:text-5xl pb-8">Testimonials</h2>
        <div className="relative xl:flex max-w-[1024px] m-auto hidden hidden xl:block">
          <div className="absolute left-[-90px] top-12">
            <div className="relative bg-white z-10 w-96 h-44 p-5 rounded-2xl shadow-xl">
              <div className="text-sm text-center font-poppins-medium text-base">
                “FINIX - TF has transformed our billing process. It’s reliable,
                user-friendly, and has significantly improved our financial management.
                A game-changer for our business!”
              </div>
              <div className="absolute bottom-[-90px] left-[110px] text-center m-auto text-black">
                <img className="size-24 rounded-full m-auto" src="./image/avatar_01.png" alt="Avatar 1" />
                <h2 className="font-montserrat-semibold text-base">Paul White</h2>
                <h4 className="font-montserrat-regular text-base">Blissful Bites Bakery</h4>
              </div>
            </div>
          </div>
          <div className="absolute left-[280px] top-[5px]">
            <div className="relative medium-blue-bg text-white z-20 w-[29rem] h-48 p-5 shadow-2xl rounded-2xl">
              <div className="text-sm text-center font-poppins-regular text-2xl">
                “FINIX - TF has revolutionized how I manage transactions for my business. It’s
                reliable, user-friendly, and has significantly improved efficiency; I’ve tried
                several billing apps, but FINIX – TF stands out with its seamless interface and
                reliable performance. Highly recommended for any business owner!”
              </div>
              <div className="absolute bottom-[-100px] left-[150px] text-center m-auto text-black">
                <img className="size-24 rounded-full m-auto" src="./image/avatar_02.png" alt="Avatar 1" />
                <h2 className="font-montserrat-semibold text-2xl">Charles Green</h2>
                <h4 className="font-montserrat-regular text-base">Gourmet Delight Market</h4>
              </div>
            </div>
          </div>
          <div className="absolute right-[-90px] top-12">
            <div className="relative bg-white z-10 w-96 h-44 p-5 rounded-2xl shadow-xl">
              <div className="text-sm text-center font-poppins-medium text-base">
                “FINIX – TF has simplified billing for our team. Its reliability and ease of use have made
                it an essential tool for our business operations. It’s user-friendly, reliable, and has
                streamlined our billing process; saving us time and effort.”
              </div>
              <div className="absolute bottom-[-90px] left-[110px] text-center m-auto text-black">
                <img className="size-24 rounded-full m-auto" src="./image/avatar_03.png" alt="Avatar 1" />
                <h2 className="font-montserrat-semibold text-base">Tatiana Gehring</h2>
                <h4 className="font-montserrat-regular text-base">Savvy Style Emporium</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="block xl:hidden max-w-[1024px] m-auto px-4">
          <Component.Carousel />
        </div>
      </div>
    </div>
  )
};

export default Home;
