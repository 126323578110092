import './App.scss';
import React, { useEffect } from "react";
import * as Component from "./Components";
import GenericRoutes from "./Routes";
import { RiWhatsappFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Component.Header />
      <GenericRoutes />
      <Component.Footer />
      <div className='fixed bottom-5 right-5 text-green-500'>
        <Link to={"https://api.whatsapp.com/send/?phone=9946459039&text&type=phone_number&app_absent=0"}>
          <RiWhatsappFill size={"50px"} />
        </Link>
      </div>
    </div>
  );
}

export default App;
