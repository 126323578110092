import "./header.scss";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";
import { RouteNavigation } from "../../Routes";
import SideBar from "../SideBar";


const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100);
        });
        return () => {
            window.removeEventListener("scroll", () => {
                setScroll(window.scrollY > 100);
            });
        };
    }, [scroll]);
    const { pathname } = useLocation();

    let stickyHeader;
    if (pathname === "/" && scroll === false) {
        stickyHeader = "sticky lg:fixed header-bg text-white";
    } else {
        stickyHeader = "bg-white sticky top-0 z-10 border-b-2 border-gray-200 text-black";
    }
    let home = pathname === "/";

    return (
        <div className={`${stickyHeader} p-4 top-0 left-0 right-0 z-30 transition-colors duration-300`}>
            <div className="relative flex items-center justify-between mx-auto max-w-[1024px]">
                <span>
                    {home ? scroll ? <img src="./image/logo_02.png" className="w-8 sm:w-16" alt="logo" /> : <img src="./image/logo.svg" className="w-8 sm:w-16" alt="logo" /> : ""}
                    {!home && <img src="./image/logo_02.png" className="w-8 sm:w-16" alt="logo" />}
                </span>
                <ul className="items-center hidden space-x-20 lg:flex font-objectivity-regular text-lg 2xl:text-2xl">
                    <li>
                        <Link
                            to={RouteNavigation.home}
                            className={`${pathname === "/" ? "border-b-2 border-[#D9AE30]" : ""} font-medium tracking-widest`}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <span
                            className="font-medium tracking-widest"
                        >
                            Features
                        </span>
                    </li>
                    <li>
                        <Link
                            to={RouteNavigation.about}
                            className={`${pathname === "/about-us" ? "border-b-2 border-[#D9AE30]" : ""} font-medium tracking-widest`}
                        >
                            About
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={RouteNavigation.contact}
                            className={`${pathname === "/contact-us" ? "border-b-2 border-[#D9AE30]" : ""} font-medium tracking-widest`}
                        >
                            Contact Us
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={"https://login.finix-tf.com/"}
                            className="font-medium tracking-widest"              >
                            Login
                        </Link>
                    </li>
                </ul>
                <div className="lg:hidden">
                    <button
                        className={`${scroll ? "text-black" : "text-white"}`}
                        onClick={() => setIsMenuOpen(true)}
                    >
                        <RxHamburgerMenu />
                    </button>
                </div>
            </div>
            {isMenuOpen && (
                <SideBar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            )}
        </div>
    )
}

export default Header;