import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel() {
  const sliderRef = useRef<Slider>(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "60px",
    autoplay: true,

  };

  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        <section className="w-full py-2">
          <div className="absolute inset-0 -z-10 bg-white opacity-20"></div>
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <figure className="mt-10">
              <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                <p>“FINIX - TF has transformed our billing process. It’s reliable,
                  user-friendly, and has significantly improved our financial management.
                  A game-changer for our business!”</p>
              </blockquote>
              <figcaption className="mt-10">
                <img className="mx-auto size-24 rounded-full" src="./image/avatar_01.png" alt="" />
                <div className="mt-2 flex flex flex-col justify-center items-center space-x-3 text-base">
                  <h2 className="font-montserrat-semibold text-2xl">Paul White</h2>
                  <h4 className="font-montserrat-regular text-base">Blissful Bites Bakery</h4>
                </div>
              </figcaption>
            </figure>
          </div>
        </section>
        <section className="w-full py-2">
          <div className="absolute inset-0 -z-10 bg-white opacity-20"></div>
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <figure className="mt-10">
              <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                <p>“FINIX - TF has revolutionized how I manage transactions for my business. It’s
                  reliable, user-friendly, and has significantly improved efficiency; I’ve tried
                  several billing apps, but FINIX – TF stands out with its seamless interface and
                  reliable performance. Highly recommended for any business owner!”</p>
              </blockquote>
              <figcaption className="mt-10">
                <img className="mx-auto size-24 rounded-full" src="./image/avatar_02.png" alt="" />
                <div className="mt-2 flex flex flex-col justify-center items-center space-x-3 text-base">
                  <h2 className="font-montserrat-semibold text-2xl">Charles Green</h2>
                  <h4 className="font-montserrat-regular text-base">Gourmet Delight Market</h4>
                </div>
              </figcaption>
            </figure>
          </div>
        </section>
        <section className="w-full py-2">
          <div className="absolute inset-0 -z-10 bg-white opacity-20"></div>
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <figure className="mt-10">
              <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                <p>“FINIX – TF has simplified billing for our team. Its reliability and ease of use have made
                  it an essential tool for our business operations. It’s user-friendly, reliable, and has
                  streamlined our billing process; saving us time and effort.”</p>
              </blockquote>
              <figcaption className="mt-10">
                <img className="mx-auto size-24 rounded-full" src="./image/avatar_03.png" alt="" />
                <div className="mt-2 flex flex flex-col justify-center items-center space-x-3 text-base">
                  <h2 className="font-montserrat-semibold text-2xl">Tatiana Gehring</h2>
                  <h4 className="font-montserrat-regular text-base">Savvy Style Emporium</h4>
                </div>
              </figcaption>
            </figure>
          </div>
        </section>
      </Slider>
    </div>
  );
}

export default Carousel;