import React from "react";

const Business = [
    {
        "id": 1,
        "title": "Restaurant",
        "image": "./image/bussiness_01.png"
    },
    {
        "id": 2,
        "title": "Bakery",
        "image": "./image/bussiness_02.png"
    },
    {
        "id": 3,
        "title": "Van Sales",
        "image": "./image/bussiness_03.png"
    },
    {
        "id": 4,
        "title": "Cake Shop",
        "image": "./image/bussiness_04.png"
    },
    {
        "id": 5,
        "title": "Salon",
        "image": "./image/bussiness_05.png"
    },
    {
        "id": 6,
        "title": "Juice Parlor",
        "image": "./image/bussiness_06.png"
    },
    {
        "id": 7,
        "title": "Fuel Outlets",
        "image": "./image/bussiness_07.png"
    },
    {
        "id": 8,
        "title": "Retail Shops",
        "image": "./image/bussiness_08.png"
    }
]
const BussinessGrid = () => {
    return (
        <div className="max-w-[1024px] m-auto px-4 pt-8 pb-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-10 gap-x-5">
                {Business?.map((data) => {
                    return (
                        <div key={data.id} className="w-fit m-auto group">
                            <div className="transition-all duration-300 delay-0 shadow-[-20px_-20px_0px_0px_#2252DC] group-hover:shadow-[20px_20px_0px_0px_#2252DC] rounded-2xl w-fit h-fit m-auto">
                                <img className="rounded-xl w-96" src={data.image} alt="bussiness_" />
                            </div>
                            <div>
                                <h2 className="pt-2 font-poppins-semibold text-2xl 2xl:text-4xl mt-2">{data.title}</h2>
                                <h4 className="pt-2 font-gotham-light text-xl 3xl:text-2xl">SOLUTIONS</h4>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>)
}
export default BussinessGrid;